* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color: #0077aa;
}

html {
  background-color: #fefefe;
}

.heading-primary {
  font-size: 40px;
  font-weight: 100;

  & + p{
    margin-top: 5px;
  }
}

.heading-secondary{
  font-weight: 100;
}

.text-article {
  font-family: "Open Sans", Arial;
  color: #454545;
  font-size: 16px;
  line-height: 1.4;
  text-align: justify;
  padding: 10px;
  border: 1px solid #dedede;

  &--no-border {
    border: none;
    padding: 0;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

p {
  margin: 16px 0;
}

.changelog-date {
  margin-bottom: 5px;
  text-decoration: underline;
}

ul {
  padding-left: 13px;
}

#container {
  margin: 50px auto;
  width: 882px;
}

#container::after {
  display: table;
  position: static;
  content: " ";
  clear: both;
}

#stats {
  padding: 10px;
  border: 1px solid #dedede;
  width: 230px;
  margin-right: 10px;
  height: 642px;
  float: left;
  font-family: monospace;
}

#statsGraph {
  margin: 10px 0;
  border: 1px solid #dedede;
}

#game {
  float: left;
  display: block;
  border: 1px solid #dedede;
}
